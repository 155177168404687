import RouteItem from "types/route-item"
import Redicrect from "UI/Redicrect"
import Pickup from "./pages/Pickup/Pickup"
import Tagging from "./pages/Tagging/Tagging"

const routes:RouteItem[] = [
    {
        route:{
            path:'',
            element:<Redicrect to="pickup"/>,
        },
        title:''
    },
    {
        route:{
            path:'pickup',
            element:<Pickup/>
        },
        title:'Самовывоз'
    },
    {
        route:{
            path:'tagging',
            element:<Tagging/>
        },
        title:'Тегирование товаров'
    },
    
]
export default routes