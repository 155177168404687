import ErrorResponce from "types/error-response";
import PickupSettings, {
  PickupSettingsWithStoreData,
  PickupSettingsWithStoreId,
} from "types/pickup-settings";

export default async (
): Promise<PickupSettingsWithStoreData[] | null | ErrorResponce> => {
    
  const token = localStorage.getItem("token");
  if (!token) return null;
  try {
    const API_URL = new URL(process.env.REACT_APP_API_URL as string);
    API_URL.pathname += `store-pickup-settings`;
    const responce = await fetch(API_URL, {
      headers: {
        "Content-Type": "application/json",
        "X-Auth-Token": token,
      },
      method: "GET",
    });
    return await responce.json();
  } catch (error) {
    console.log("🚀 ~ error:", error);
    return null;
  }
};
