import { AnimatePresence, motion } from "framer-motion";
import { useState, useRef, useLayoutEffect, useEffect, useMemo } from "react";
import { v4 } from "uuid";

type Params = {
  top?:number
  isError?:boolean
  bottomHint?:boolean

}

export default (text: string,params?:Params) => {
  const [showed, setShowed] = useState(false);
  
  const [position, setPosition] = useState(0);
  const [textContent, setTextContent] = useState(text)
  const hintRef = useRef<HTMLDivElement | null>(null);

  const calculatePosition = () => {
    if (hintRef.current && showed) {
      const {x,width} = hintRef.current.getBoundingClientRect();
      const viewportWidth = window.innerWidth;

      let newOffset = 0;
      const offset = 40; // Отступ от границ

      if (x + width > viewportWidth - offset) {
        newOffset = (viewportWidth) - (x + width) - offset
      }

      setPosition(newOffset);
    }
  };

  useLayoutEffect(() => {
    if (showed) {
      calculatePosition();
    } else setPosition(0)
  }, [showed]);

  const key = useMemo(() => v4(),[])
  const minus = params?.bottomHint ? "" : "-"
  const hint = (
    <AnimatePresence>
      {showed && (
        <motion.div
          onPointerEnter={(e) => {
            e.stopPropagation()
          }}
          ref={hintRef}
          data-error-hint={params?.isError}
          data-bottom-hint={params?.bottomHint}
          initial={{ opacity: 0, top: 0 ,transform:`translate(-50%, ${minus}100%) scale(0.9)`}}
          animate={{ opacity: 1, top: typeof params?.top === 'number' ? params?.top : -17,transform:`translate(-50%, ${minus}100%) scale(1)`}}
          exit={{ opacity: 0, top: 0 ,transform:`translate(-50%, ${minus}100%) scale(0.7)`}}
          transition={{
            ease: "circInOut",
            duration: 0.25,
          }}
          key={key}
          style={{left:`calc(50% + ${position}px)`}}
          className="hint-body"
        >
          <motion.div style={{overflow:'hidden'}} layout>
          <AnimatePresence initial={false} mode="wait">
            <motion.p
              key={textContent}
              initial={{y:'-50%',opacity:0}}
              animate={{y:'0%',opacity:1}}
              exit={{y:'50%',opacity:0}}
              transition={{duration:0.1,ease:'easeInOut'}}
              dangerouslySetInnerHTML={{ __html: textContent.replace(/\n/g, "<br />") }}
            />
          </AnimatePresence>
          </motion.div>
          <span style={{
            transform:`translate(calc(-50% - ${position}px), ${params?.bottomHint ? "-" : ""}100%) 
            ${params?.bottomHint ? 'rotate(180deg)' : ""}`
            }}/>
        </motion.div>
      )}
    </AnimatePresence>
  );

  const show = () => {
    setShowed(true)
  }
  const hide = () => {
    setShowed(false)
  };

  return { hint, hide, show,setTextContent ,showed};
};
