import ErrorResponce from "types/error-response";
import PickupSettings, {
  PickupSettingsWithStoreId,
} from "types/pickup-settings";

export default async (
  storeId: string,
  settings: PickupSettings
): Promise<PickupSettingsWithStoreId | null | ErrorResponce> => {
    
  const token = localStorage.getItem("token");
  if (!token) return null;
  try {
    const API_URL = new URL(process.env.REACT_APP_API_URL as string);
    API_URL.pathname += `store-pickup-settings/${storeId}`;
    const responce = await fetch(API_URL, {
      headers: {
        "Content-Type": "application/json",
        "X-Auth-Token": token,
      },
      body: JSON.stringify(settings),
      method: "PUT",
    });
    return await responce.json();
  } catch (error) {
    console.log("🚀 ~ error:", error);
    return null;
  }
};
