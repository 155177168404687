import Button from "UI/Button";
import styles from "../Pickup.module.css";
import { useContext } from "react";
import { FilterContext } from "pages/Main/pages/Analytics/components/DropDown/DropDown";

type PickupSelectFormatProps = {
  value:boolean
  setFastPickup: (value: boolean) => void;
};

const PickupSelectFormat = ({ setFastPickup ,value}: PickupSelectFormatProps) => {
  const {close} = useContext(FilterContext)
  return (
    <ul className={styles.pickupFormatOptions}>
      {[true,false].map((opt,i) => (
        <li key={i} className={opt === value ? styles.pickupFormatSelectedOpt : ''}><Button onClick={() => {
          setFastPickup(opt)
          close()
        }}>{PickupFormatOptionName(opt)}</Button></li>
      ))}
      
    </ul>
  );
};

export const PickupFormatOptionName = (value: boolean) =>
  value ? "Ближайшее время" : "Заказ ко времени";

export default PickupSelectFormat;
