import { createContext, useContext, useEffect, useState } from "react";
import styles from "./Transactions.module.css";
import TransactionsDatePicker from "./components/TransactionsDatePicker/TransactionsDatePicker";
import TransactionFilter from "../../components/DropDown/DropDown";
import OrdersFilters from "types/orders-filters";
import datePresets from "./components/TransactionsDatePicker/date-presets";
import { Dayjs } from "dayjs";
import Icon from "UI/Icon";
import TransactionsInfoBlocks from "./components/Statistics/TransactionsStatistics";
import TransactionsTable from "./components/TransactionsTable/TransactionsTable";
import DateRange from "types/date-range";
import { useSearchParams } from "react-router-dom";
import appContext from "utils/app-context";
import TransactionOverView from "./components/TransactionsTable/components/TransactionOverView";
import ListFilter from "./components/ListFilter/ListFilter";
import orderTypes from "utils/order-filter-types";
import paymentMethodsFilterTypes from "utils/payment-methods-filter-types";
import tagsFilterTypes from "utils/tags-filter-types";
import diff from 'deep-diff'
import Button from "UI/Button";
import RatingFilter from "./components/RatingFilter/RatingFilter";
import AddressesFilter from "../../components/AddressesFilter/AddressesFilter";
import { MainContext } from "pages/Main/Main";
const f = (d: Dayjs) => d.format("DD.MM.YY");

const defaultFilters:OrdersFilters = {
  range: datePresets.month.getRange(),
  storeIds: [],
  orderTypes:[],
  paymentMethods:[],
  tags:[],
  ratings:[],
  pageSize:25,
  customerNumber:''
}

export const TransactionsContext = createContext({filters:defaultFilters})

type Time = `${number}:${number}`
export type TimeRange = {start:Time,end:Time} | false

const Transactions = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  const { setModal } = useContext(appContext);
  const {addressesService:{adresses,loading}} = useContext(MainContext)
  const [filters, setfilter] = useState<OrdersFilters>(defaultFilters);
  const [time, setTime] = useState<TimeRange>(false)
  const { range } = filters;
  
  const datePickerProps = {
    selectedRange: filters.range,
    selectRange: (range: DateRange) => setfilter({ ...filters, range }),
    time,setTime
  };
  
  const addressesFilterProps = {
    addresses: adresses,
    selected: filters.storeIds,
    setSelected: (storeIds: any) => setfilter({ ...filters, storeIds }),
  };
  const adrsCount = filters.storeIds.length;
  const PayMethodsCount = filters.paymentMethods.length;
  const tagsCount = filters.tags.length;
  const ratingsCount = filters.ratings.length;
  const orderTypesCount = filters.orderTypes.length;
  
  const frstAdrsID = filters.storeIds[0];

  const orderTypesFilterProps = {
    allTypes:"Все типы заказа",
    list:orderTypes,
    selected:filters.orderTypes,
    setSelected:(orderTypes:OrdersFilters['orderTypes']) => setfilter({...filters,orderTypes})
  }

  const paymentMethodFilterProps = {
    allTypes:"Все способы оплаты",
    list:paymentMethodsFilterTypes,
    selected:filters.paymentMethods,
    setSelected:(paymentMethods:OrdersFilters['paymentMethods']) => setfilter({...filters,paymentMethods})
  }
  const tagsFilterProps = {
    allTypes:"Все теги",
    list:tagsFilterTypes,
    selected:filters.tags,
    setSelected:(tags:OrdersFilters['tags']) => setfilter({...filters,tags})
  }

  useEffect(() => {
    const id = searchParams.get("id");
    if(id) setModal({ content: <TransactionOverView id={id}/> });
    else setModal(null)
    // setModal({ content: <TransactionOverView id={'ff53fb2a-32c0-43f4-abcb-561f333ccb35'}/> });
  }, [searchParams]);

  const resetIsAvalaible = diff(defaultFilters,filters) || time

  return (
    <TransactionsContext.Provider value={{filters}}>
    <div className={styles.page}>
      <div className={styles.filtersWrapper}>

      <div className={styles.filtersContainer} data-loading={loading}>
        <TransactionFilter
          filterComponent={<TransactionsDatePicker {...datePickerProps} />}
        >
          <Icon icon="calendar" />
          <span>
            {range.end.isSame(range.start, "day")
              ? f(range.start)
              : `${f(range.start)} - ${f(range.end)}`}
          </span>
        </TransactionFilter>
        <TransactionFilter
          hasValue={!!filters.storeIds.length}
          filterComponent={<AddressesFilter {...addressesFilterProps} />}
        >
          <span className={styles.shortText}>
            {adrsCount > 0
              ? adrsCount > 1
                ? `Адреса: ${adrsCount}`
                : adresses?.find(({ storeId }) => frstAdrsID === storeId)
                    ?.address
              : "Адреса"}
          </span>
        </TransactionFilter>
        <TransactionFilter hasValue={!!filters.orderTypes.length} filterComponent={<ListFilter {...orderTypesFilterProps}/>}>
          <span>{orderTypesCount > 0
              ? orderTypesCount > 1
                ? `Типы заказа: ${orderTypesCount}`
                : orderTypes?.find(({ value }) => value === filters.orderTypes[0])
                    ?.title
              : "Типы заказа"}</span>
        </TransactionFilter>
        <TransactionFilter hasValue={!!filters.paymentMethods.length} filterComponent={<ListFilter {...paymentMethodFilterProps}/>}>
          <span>{PayMethodsCount > 0
              ? PayMethodsCount > 1
                ? `Способы оплаты: ${PayMethodsCount}`
                : paymentMethodsFilterTypes?.find(({ value }) => value === filters.paymentMethods[0])
                    ?.title
              : "Способы оплаты"}</span>
        </TransactionFilter>
        <TransactionFilter hasValue={!!filters.tags.length} filterComponent={<ListFilter {...tagsFilterProps}/>}>
        <span>{tagsCount > 0
              ? tagsCount > 1
                ? `Теги: ${tagsCount}`
                : tagsFilterTypes?.find(({ value }) => value === filters.tags[0])
                    ?.title
              : "Теги"}</span>
        </TransactionFilter>
        <TransactionFilter hasValue={!!filters.ratings.length} filterComponent={<RatingFilter selected={filters.ratings} setSelected={(ratings) => setfilter({...filters,ratings})}/>}>
        <span>{ratingsCount > 0
              ? (filters.ratings[0] === 0 ? 'Без оценки' : `Оценки: ${ratingsCount}`)
              : "Оценка"}</span>
        </TransactionFilter>
      </div>
        {resetIsAvalaible && <Button onClick={() => {
          setfilter(defaultFilters)
          setTime(false)
        }} className={styles.resetButton}>Сбросить все</Button>}
      </div>
      <TransactionsInfoBlocks />
      <TransactionsTable filters={filters} setFilters={setfilter} time={time}/>
    </div>
    </TransactionsContext.Provider>
  );
};

export default Transactions;
