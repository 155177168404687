import dayjs from "dayjs";
import {
  motion,
  Reorder,
  useDragControls,
  Variants,
} from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import styles from "../Stories.module.css";
import Button from "UI/Button";
import Icon, { IconType } from "UI/Icon";
import useStoryActions from "hooks/use-story-actions";
import Story, { StoryStatus, StoryStatusDisplay } from "types/story";
import useHint from "hooks/use-hint";
import { useNavigate } from "react-router-dom";
const isDev = process.env.REACT_APP_IS_DEV
type Props = {
  story: Story;
  updateList: () => Promise<any>;
  setDragableStory:(story:Story['id'] | null) => void
  isdragable:Story['id'] | null
};

const formatDate = (date: number,f?:string) => dayjs(date).format(f || "DD.MM.YY");

const StoriesTableRow = ({ story, updateList,setDragableStory,isdragable }: Props) => {
  const controls = useDragControls();
  const nav = useNavigate()
  const playHint = useHint('Опубликовать')
  const pauseHint = useHint('Остановить публикацию')
  const dragHint = useHint('Перетяните для перемещения')
  
  const { title, id, archivedAt, status, createdAt,displayOrderIndex,publishedAt } = story;
  
  const {
    handlePublish,
    handleUnpublish,
    handleArchive,
    handleUnArchive,
    handleDelete,
  } = useStoryActions(story, updateList);

  useEffect(() => {
    if(isdragable) dragHint.hide()
  }, [isdragable])

  return (
    <Reorder.Item
      value={story}
      dragListener={false}
      dragControls={controls}
      exit={{ opacity: 0, scale: 0.8 }}
      transition={{duration:0.2,ease:'circInOut'}}
      variants={variants}
      whileDrag={{
        boxShadow: "0px 0px 10px rgba(15, 15, 15, 0.10)",
        borderRadius: 6,
        scale: 1.01,
        zIndex:10
      }}
      onClick={() => nav(`../story/${story.id}`)}
    >
      <ul className={styles.newsTableRow}>
        <li>
          {formatDate(createdAt)}
          {status === StoryStatus.ARCHIVED && archivedAt && (
            <span>В архиве с {formatDate(archivedAt)}</span>
          )}
           {status === StoryStatus.PUBLISHED && publishedAt && (
            <span>Опубликована {formatDate(publishedAt)} {formatDate(publishedAt,'HH:mm')}</span>
          )}
        </li>
        <li>{title}
          {DEV && <span>ID:{id} | displayOrderIndex:{displayOrderIndex}</span>}
        </li>
        <li>
          <p
            className={`${styles.storyStatus} ${
              styles[`storyStatus__${status}`]
            }`}
          >
            {StoryStatusDisplay[status]}
          </p>
        </li>
        <li onClick={(e) => e.stopPropagation()}>
          <div className={styles.actions}>
            {status === StoryStatus.UNPUBLISHED && (
              <div>
                <Button
                  className={styles.actionButton}
                  data-black
                  handlePromise={handlePublish}
                  icon="play"
                  show-loading
                  onPointerEnter={playHint.show}
                  onPointerLeave={playHint.hide}
                />
                {playHint.hint}
              </div>
              
            )}
            {status === StoryStatus.PUBLISHED && (
              <div>
                  <Button
                className={styles.actionButton}
                handlePromise={handleUnpublish}
                icon="pause"
                show-loading
                onPointerEnter={pauseHint.show}
                onPointerLeave={pauseHint.hide}
                />
                {pauseHint.hint}
              </div>
            )}
            {status !== StoryStatus.ARCHIVED && (
              <ActionButtonDropdown
                icon="archive"
                headerText="Переместить в архив?"
                description="Новость будет перемещена в «Архив»."
                confirmText="Переместить"
                confirmDataAttr="data-black"
                hintText="Переместить в архив"
                onConfirm={handleArchive}
              />
            )}
            {status === StoryStatus.ARCHIVED && (
              <>
                <ActionButtonDropdown
                  icon="return"
                  headerText="Вернуть новость?"
                  description="Новость будет перемещена в раздел «Текущие» со статусом «Не опубликована»."
                  confirmText="Вернуть"
                  confirmDataAttr="data-black"
                  hintText="Вернуть новость"
                  onConfirm={handleUnArchive}
                />
                <ActionButtonDropdown
                  icon="delete"
                  headerText="Удалить новость?"
                  description="Новость будет удалена без возможности восстановления."
                  confirmText="Удалить"
                  confirmDataAttr="data-red"
                  hintText="Удалить"
                  onConfirm={handleDelete}
                />
              </>
            )}
            {status !== StoryStatus.ARCHIVED && (
              <div>
                <Button
                className={`${styles.actionButton} ${styles.actions__dragButton}`}
                onPointerDown={(e) => {
                  controls.start(e)
                  setDragableStory(story.id)
                  dragHint.hide()
                }}
                onPointerEnter={isdragable ? undefined : dragHint.show}
                onPointerLeave={dragHint.hide}
                icon="burger"
              />
              {dragHint.hint}
              </div>
            )}
          </div>
        </li>
        <li></li>
      </ul>
    </Reorder.Item>
  );
};

type ActionButtonDropdownProps = {
  icon: IconType;
  headerText: string;
  description: string;
  confirmText: string;
  hintText: string;
  confirmDataAttr?: string; // data attribute for the confirm button
  onConfirm?: () => any;
  onPress?: () => any;
  
};

export const ActionButtonDropdown = ({
  icon,
  headerText,
  description,
  confirmText,
  confirmDataAttr,
  hintText,
  onConfirm,
  onPress
}: ActionButtonDropdownProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const HINT = useHint(hintText)
  const handleClose = () => setIsOpen(false);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (!ref.current?.contains(event.target as Node)) {
        handleClose();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <div className={styles.actionsButtonWrapper} ref={ref}>
      {HINT.hint}
      <Button 
      onPointerEnter={HINT.show}
      onPointerLeave={HINT.hide}
      className={styles.actionButton} onClick={() => onPress ? onPress() : setIsOpen(!isOpen)}>
        <Icon icon={icon} />
      </Button>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0, bottom: -50 }}
          animate={{ opacity: 1, bottom: -10 }}
          transition={{ duration: 0.15 }}
          className={styles.dropdown}
        >
          <div className={styles.dropdownHeader}>
            <h4>{headerText}</h4>
            <p>{description}</p>
          </div>
          <div className={styles.dropdownActions}>
            <Button data-white onClick={handleClose}>
              Оставить
            </Button>
            <Button
              {...{ [confirmDataAttr || '']: true }}
              onClick={async () => {
                if(onConfirm) await onConfirm();
                handleClose();
              }}
            >
              {confirmText}
            </Button>
          </div>
        </motion.div>
      )}
    </div>
  );
};

const variants = {
  open: {
    y: 0,
    opacity: 1,
  },
  closed: {
    y: 10,
    opacity: 0,
  }
};
export default StoriesTableRow;
