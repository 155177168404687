import axios from "axios";
import ErrorResponce from "types/error-response";
import Story from "types/story";
import { Product, SortOptions } from "types/tagging";
interface Responce {
  items: Product[];
  total: number;
  page: number;
  size: number;
  totalPages: number;
}

type Params = {
    productName?:string
    page?:number
    size?:number
    sortBy:SortOptions

}

  export default async (params?:Params,signal?:AbortSignal): Promise<Responce | null | ErrorResponce | 'canceled'> => {
    const token = localStorage.getItem('token')
    if(!token) return null
    try {
      const API_URL = new URL(process.env.REACT_APP_API_URL as string);
      API_URL.pathname += "product-tags";
        if(params) {
          for (const key of Object.keys(params)) {
            API_URL.searchParams.append(key,String(params[key as keyof Params]))
          }
        }
      const responce = await axios.get(API_URL.toString(), {
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": token,
        },
        signal,
        method: "get",
      });
      return responce.data;
    } catch (error:any) {
      if (error.name === "CanceledError") {
        return 'canceled'
      } else {
        console.error("Request error:", error.message || error);
      }
  
      return null;
    }
  };
  