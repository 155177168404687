import React, { Dispatch, SetStateAction, useContext } from 'react'
import styles from '../Tagging.module.css'
import { AnimatePresence, motion } from 'framer-motion'
import { Product, Tag } from 'types/tagging'
import { ActionButtonDropdown } from 'pages/Main/pages/Card&News/pages/Stories/components/StoriesTableRow'
import dayjs from 'dayjs'
import TaggingTagSelect from './TaggingTagSelect'
import deleteProduct from 'api/tagging/delete-product'
import { toast } from 'react-toastify'
import appContext from 'utils/app-context'
import TaggingNameEdit from './TaggingNameEdit'
import { notification } from 'hooks/use-notifications'
import setProductTags from 'api/tagging/set-product-tags'
type Props = {
    p:Product
    setProducts:Dispatch<SetStateAction<Product[]>>
    setTotal:Dispatch<SetStateAction<number>>
    
}

const TaggingProductRow = ({p,setProducts,setTotal}: Props) => {
  const {setModal} = useContext(appContext)

  const handleProductDelete = async () => {
    const res = await deleteProduct(p.id)
    if (!res) notification("Не удалось удалить продукт",'warning');
    else if (typeof res !== 'boolean' && "description" in res)
      notification(`Не удалось удалить продукт: ${res.description}`,'warning');
    else {
      setProducts(prev => {
        return prev.filter(({id}) => id !== p.id)
      })
      notification(`Товар удален`,'success');
      setTotal(prev => prev-1)
    }
  }

  const handleUpdateTags = async (tags:Tag[]) => {
    tags.sort((A,B) => A.text.length - B.text.length)

    const res = await setProductTags(p.id,tags)
    if (!res) toast.warning(`Не удалось сохранить теги для "${p.productName}"`);
        else if ("description" in res)
          toast.warning(`Не удалось сохранить теги для "${p.productName}": ${res.description}`);
        else {
          console.log("🚀 ~ handleUpdateTags ~ res:", res)
          setProducts(prev => {
            return prev.map((P) => p.id === P.id ? res : P)
          })
          notification(p.tags.length < tags.length ? 'Тэги добавлены' : 'Теги обновлены','success')
        }
  }

  return (
    <motion.tr
    layout
    exit={{ opacity: 0, scale: 0.8,}}
    transition={{duration:0.2,ease:'circInOut'}}
    variants={variants}
     className={styles.productRow}>
      <td className={styles.productRowProductName}>{p.productName}</td>
      <td>
        <TaggingTagSelect p={p} setTags={t => handleUpdateTags(t)}/>
      </td>
      <td className={styles.productRowProductName}>{dayjs(p.updatedAt).format("DD.MM.YY")}</td>
      <td>
        <div className={styles.productRowActions}>
        <ActionButtonDropdown
            icon="edit"
            confirmDataAttr=""
            description=""
            headerText=""
            confirmText=""
            hintText="Переименовать"
            onPress={
              () => 
                setModal({content:<TaggingNameEdit p={p} setProducts={setProducts}/>,close:true})
            }
          />
          <ActionButtonDropdown
            icon="delete"
            headerText="Удалить товар?"
            description="Товар будет удален из таблицы тегирования."
            confirmText="Удалить"
            confirmDataAttr="data-red"
            hintText="Удалить"
            onConfirm={handleProductDelete}
          />
        </div>
      </td>
    </motion.tr>
  );
}
const variants = {
    open: {
      y: 0,
      opacity: 1,
    },
    closed: {
      y: 10,
      opacity: 0,
    }
  };

export default TaggingProductRow
