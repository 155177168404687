import styles from "./Pickup.module.css";
import IllustrationCard from "assets/images/pickup-card.svg";
import Button from "UI/Button";
import Switch from "UI/Switch";
import PickupTableRow from "./components/PickupTableRow";
import { useContext, useEffect, useRef, useState } from "react";
import { MainContext } from "pages/Main/Main";
import { registerToggle } from "react-toastify/dist/core/store";

const Pickup = () => {
  const { PickupSettingsService ,LicensesService:{licenses}} = useContext(MainContext);
  const [hasErrors, sethasErrors] = useState(false)
  const tableRef = useRef<HTMLTableElement>(null)
  const {
    generalParamsForAll,
    generalSettings,
    saveAvalaible,
    stores,
    handleSave,
    setGeneralParamsForAll,
    setStores,
    setgeneralSettings,
  } = PickupSettingsService;
  useEffect(() => {
    const errorNodes = tableRef.current?.querySelectorAll('input[data-error="true"]')
    if(errorNodes?.length) sethasErrors(true)
    else sethasErrors(false)
  }, [stores,generalSettings])
  return (
    <div className={styles.sectionContent}>
      <div className={styles.pageDescContainer}>
        <img src={IllustrationCard} alt="" />
        <div className={styles.pageDescTextContent}>
          <h2>Все заказы вовремя!</h2>
          <p>
            Оптимизируйте процесс обслуживания покупателей и удобство получения
            заказа. Настройте <br /> параметры для самовывоза, которые подходят
            вашему бизнесу.
          </p>
        </div>
      </div>
      <div className={styles.sectionTitle}>
        <hr className={styles.line} />

        <div className={styles.sectionTitleRow}>
          <h2>Настройки самовывоза</h2>
          <Button
            handlePromise={handleSave}
            disabled={!saveAvalaible || hasErrors}
            data-black
            icon={undefined}
            show-loading
          >
            Сохранить
          </Button>
        </div>
      </div>
      <div className={styles.settingsContainer}>
        <div className={styles.settingsContainerTop}>
          <Switch
            setValue={setGeneralParamsForAll}
            value={generalParamsForAll}
          />
          <p>Общие параметры для всех локаций</p>
        </div>
        <div className={styles.settingsTableContainer}>
          <table className={styles.settingsTable} ref={tableRef}>
            <thead>
              <tr>
                <td>
                  <span className={styles.settingsTableSubTitle}>Локация</span>
                </td>
                <td>
                  <span className={styles.settingsTableSubTitle}>
                    Самовывоз
                  </span>
                </td>
                <td>
                  <span className={styles.settingsTableSubTitle}>
                    Параметры
                  </span>
                </td>
              </tr>
            </thead>
            <tbody>
              {generalParamsForAll
                ? generalSettings && (
                    <PickupTableRow
                      key={"general"}
                      data={generalSettings}
                      editSettings={(data) => setgeneralSettings(data)}
                    />
                  )
                : stores?.map((store, i) => (
                    <PickupTableRow
                      key={store.storeId}
                      data={store}
                      editSettings={(data) => {
                        setStores((prev) => {
                          if (!prev) return prev;
                          if ("storeId" in data) {
                            return prev.map((Str) => {
                              return Str.storeId === store.storeId ? data : Str;
                            });
                          }
                          return prev;
                        });
                      }}
                    />
                  ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Pickup;
