import Icon from "UI/Icon"
import AnalyticsRoutes from "./pages/Analytics/Analytics.routes"
import SwipAIRoutes from "./pages/SwipAI/SwipAI.routes"
import RouteItem, { RoutesCategory } from "types/route-item"
import Redicrect from "UI/Redicrect"
import MainPage from "./MainPage"
import CardNewsRoutes from "./pages/Card&News/Card&News.routes"
import PickupDeliveryRoutes from "./pages/Modules/Pickup&Delivery/Pickup&Delivery.routes"
import { RouteObject } from "react-router-dom"



const routes:RoutesCategory[] = [
    {
        routes:[
            {
                route:{
                    path:'',
                    element:<Redicrect to="card&stories"/>,
                },
                title:''
            },
            // {
            //     route:{
            //         path:'swip-ai',
            //         element:<MainPage routes={SwipAIRoutes} title="SWiP AI"/>,
            //         children: SwipAIRoutes.map(({route}) => route)
            //     },
            //     title:'SWiP AI',
            //     navBarIcon:<Icon icon="navigationSwipAi"/>
            // },
            {
                route:{
                    path:'analytics',
                    element:<MainPage routes={AnalyticsRoutes} title="Аналитика"/>,
                    children: AnalyticsRoutes.map(({route}) => route)
                },
                title:'Аналитика',
                navBarIcon:<Icon icon="navigationAnalytics"/>
            },
            {
                route:{
                    path:'card&stories',
                    element:<MainPage routes={CardNewsRoutes} title="Карта и Медиа"/>,
                    children: CardNewsRoutes.map(({route}) => route)
                },
                title:'Карта и Медиа',
                navBarIcon:<Icon icon="navigationCardNews"/>
            },
        ]
    },
    {
        title:'Модули',
        routes:[
            {
                route:{
                    path:'pickup&delivery',
                    element:<MainPage routes={PickupDeliveryRoutes} title="Самовывоз и доставка"/>,
                    children: PickupDeliveryRoutes.map(({route}) => route)
                },
                title:'Самовывоз и доставка',
                navBarIcon:<Icon icon="navigationPickUp"/>
            },
        ]
    }
    
    
]

export const allRoutes =  routes.reduce(
        (all:RouteObject[], { routes }) => [
          ...all,
          ...(routes.map(({ route }) => route)),
        ],
        []
      )

export default routes