import React, { Dispatch, SetStateAction, useContext, useState } from "react";
import styles from "../Tagging.module.css";
import Button from "UI/Button";
import { Product } from "types/tagging";
import appContext from "utils/app-context";
import setProductName from "api/tagging/set-product-name";
import { notification } from "hooks/use-notifications";
type Props = {
  p: Product;
  setProducts: Dispatch<SetStateAction<Product[]>>;
};

const TaggingNameEdit = ({ p, setProducts }: Props) => {
  const [name, setname] = useState(p.productName);
  const { setModal } = useContext(appContext);

  const handleSaveName = async () => {
    const res = await setProductName(p.id, name);
    if (!res) notification(`Не удалось обновить название `);
    else if ("description" in res){
        if(res.code === 8) {
            notification(`Не удалось обновить название: У вас уже есть товар с таким названием`,'warning');
        } else notification(`Не удалось обновить название: ${res.description}`,'warning');
    } else {
      setProducts((prev) => {
        if (!prev) return prev;
        return prev.map((P) => (p.id === P.id ? res : P));
      });
      setModal(null)
      notification("Название обновлено", "success");
    }
  };

  return (
    <div className={styles.renameProductBody}>
      <h2>Переименовать товар</h2>
      <div className={styles.renameProductInputContainer}>
        <label htmlFor="editProductName">Название товара</label>
        <input
        autoFocus
          type="text"
          placeholder="Капучино 300 мл"
          value={name}
          onKeyDown={(e) => {
            if(e.key === 'Enter') handleSaveName()
          }}
          onChange={(e) => setname(e.target.value)}
        />
      </div>
      <div className={styles.renameProductButtons}>
        <Button data-white onClick={() => setModal(null)}>
          Отмена
        </Button>
        <Button
          data-black
          disabled={name.trim().length === 0}
          show-loading
          handlePromise={handleSaveName}
        >
          Сохранить
        </Button>
      </div>
    </div>
  );
};

export default TaggingNameEdit;
