import React, { useContext, useEffect, useMemo, useState } from "react";
import styles from "../Pickup.module.css";
import { v4 } from "uuid";
import useHint from "hooks/use-hint";
import { MainContext } from "pages/Main/Main";

type Props = {
  label?: string;
  initialValue?: string;
  handleChangeValue?: (value: string) => void;
};

const PickupInput = ({
  label,
  initialValue = "",
  handleChangeValue,
}: Props) => {
  const [value, setValue] = useState(initialValue);
  const [isFocused, setisFocused] = useState(false)
  const ref = React.useRef<HTMLSpanElement>(null);
  const input = React.useRef<HTMLInputElement>(null);
  const [width, setWidth] = useState(7);
  const htmlFor = useMemo(() => v4(), []);

  const errorHint = useHint("Значение должно быть не менее 1", {
    bottomHint: true,
    isError: true,
    top:4
  });

  // Обработчик изменения значения
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    // Разрешить только цифры
    if (/^\d*$/.test(inputValue)) {
      const val = String(Number(inputValue.slice(0, 3)));
      setValue(val);
      if (handleChangeValue) {
        handleChangeValue(val);
      }
    }
  };

  useEffect(() => {
    if (ref.current) {
      setWidth(ref.current.offsetWidth);
    }

   if(isFocused) {
      if(value === '0') errorHint.show()
      else errorHint.hide()
   } else errorHint.hide()
  }, [value,isFocused]);

  useEffect(() => {
    if(!input.current) return
    const handleFocus = () => setisFocused(true)
    const handleBlur = () => setisFocused(false)
    input.current.addEventListener('focus',handleFocus)
    input.current.addEventListener('blur',handleBlur)

    return () => {
    if(!input.current) return
      input.current.removeEventListener('focus',handleFocus)
      input.current.removeEventListener('blur',handleBlur)
    }

  }, [])

  return (  
    <label htmlFor={htmlFor} className={styles.tableRowParamsInputContainer}>
      <input
        type="text"
        data-error={value === "0"}
        size={value.length || 1} // Установить ширину на основе длины контента
        id={htmlFor}
        ref={input}
        autoComplete="off"
        value={value}
        style={{ width }}
        onChange={handleChange}
      />
      {label && <span>{label}</span>}
      <span
        ref={ref}
        style={{
          position: "absolute",
          zIndex: -1,
          opacity: 0,
        }}
      >
        {value}
      </span>
      {errorHint.hint}
    </label>
  );
};

export default PickupInput;
