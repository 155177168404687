import React, { useState } from "react";

type Props = {
  value: boolean;
  setValue: (b:boolean) => void;
  locked?: boolean;
};

const Switch = ({ setValue, value,locked }: Props) => {

  return (
    <button className="switch-body" data-checked={value} data-locked={locked} onClick={() => {
      if(locked) return
      setValue(!value)
    }}>
      <span className="switch-circle" />
    </button>
  );
};

export default Switch;
