import Switch from "UI/Switch";
import styles from "../TransactionsDatePicker.module.css";
import { AnimatePresence, motion } from "framer-motion";
import { TimeRange } from "../../../Transactions";

type Props = {
  time: TimeRange;
  setTime: (t: TimeRange) => void;
};

const TransactionsTimePicker = ({ setTime, time }: Props) => {
  // Функция обработки ввода
  const handleTimeChange = (key: "start" | "end") => (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!time) return;

    let input = e.target.value.replace(/\D/g, ""); // Убираем все нецифровые символы

    if (input.length > 4) input = input.slice(0, 4); // Ограничение на 4 цифры

    // Добавляем `:`, если введено минимум 3 цифры
    if (input.length > 2) input = `${input.slice(0, 2)}:${input.slice(2)}`;

    setTime({ ...time, [key]: input });
  };

  // Коррекция времени при потере фокуса
  const handleBlur = (key: "start" | "end") => () => {
    if (!time) return;
    
    let [hh, mm] = time[key].split(":").map(Number);

    hh = Math.min(hh || 0, 23);
    mm = Math.min(mm || 0, 59);

    const correctedTime = `${hh.toString().padStart(2, "0")}:${mm.toString().padStart(2, "0")}`;
    setTime({ ...time, [key]: correctedTime });
  };

  return (
    <div className={styles.setTimeContainer}>
      <div className={styles.setTimeSwitchContainer}>
        <Switch setValue={(v) => setTime(v ? { start: "00:00", end: "23:59" } : false)} value={!!time} />
        <p>Указать время внутри дня</p>
      </div>

      <AnimatePresence>
        {time && (
          <motion.div initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} exit={{ opacity: 0, y: 20 }} className={styles.setTimeDisplay}>
            <input type="text" value={time.start} onChange={handleTimeChange("start")} onBlur={handleBlur("start")} maxLength={5} />
            <p>–</p>
            <input type="text" value={time.end} onChange={handleTimeChange("end")} onBlur={handleBlur("end")} maxLength={5} />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default TransactionsTimePicker;
