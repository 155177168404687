import { motion, AnimatePresence } from "framer-motion";
import DropDown, {
  FilterContext,
} from "pages/Main/pages/Analytics/components/DropDown/DropDown";
import styles from "../Tagging.module.css";
import { Product, Tag, TagList } from "types/tagging";
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import Button from "UI/Button";
import { diff } from "deep-diff";
import useHint from "hooks/use-hint";

const TAG_HINT_COUNTER_NAME = "tagHintCounter";

type Props = {
  p: Product;
  setTags: (t: Tag[]) => void;
};

const TaggingTagSelect = ({ p, setTags }: Props) => {
  const [localTags, setLocalTags] = useState(p.tags);
  const { hide, hint, show, showed } = useHint(
    "Выбрать можно не более 3 тегов"
  );

  const addOrRemoveTag = (tag: Tag) => {
    setLocalTags((prev) => {
      const exists = prev.some(({ text }) => text === tag.text);
      let newTags = exists
        ? prev.filter(({ text }) => text !== tag.text)
        : [...prev, tag];
      if (newTags.length > 3) {
        newTags = newTags.slice(-3);
        if (!showed) {
          const counter = Number(
            localStorage.getItem(TAG_HINT_COUNTER_NAME) || "0"
          );
          if (counter < 3) {
            localStorage.setItem(TAG_HINT_COUNTER_NAME, String(counter + 1));
            show();
            setTimeout(() => hide(), 1500);
          }
        }
      }
      return newTags;
    });
  };
  return (
    <DropDown
      onClose={() => setLocalTags(p.tags)}
      filterComponent={
        <TaggingTagSelectList
          localTags={localTags}
          setLocalTags={setLocalTags}
          confirmTags={() => setTags(localTags)}
          confirmAvailable={!!diff(localTags, p.tags)}
          addOrRemoveTag={addOrRemoveTag}
        />
      }
    >
      {hint}
      {localTags.length === 0 && (
        <p className={styles.productRowProductName}>Выберите теги</p>
      )}
      <motion.ul className={styles.productRowTags}>
        <AnimatePresence>
          {localTags.map(({ color, text }) => (
            <motion.li
              key={text}
              layout
              style={{ background: color }}
              className={styles.productRowTagBody}
            >
              {text}
            </motion.li>
          ))}
        </AnimatePresence>
      </motion.ul>
    </DropDown>
  );
};

type TaggingTagSelectListProps = {
  localTags: Tag[];
  setLocalTags: Dispatch<SetStateAction<Tag[]>>;
  confirmTags: () => void;
  confirmAvailable: boolean;
  addOrRemoveTag: (tag: Tag) => void;
};

const TaggingTagSelectList = ({
  confirmTags,
  setLocalTags,
  localTags,
  confirmAvailable,
  addOrRemoveTag,
}: TaggingTagSelectListProps) => {
  const { close } = useContext(FilterContext);

  const handleConfirm = async () => {
    await confirmTags();
    close();
  };

  return (
    <div className={styles.tagListBody}>
      <div className={styles.tagListContainer}>
        <ul className={styles.tagList}>
          {TagList.map((tag) => (
            <li
              key={tag.text}
              data-is-selected={localTags.some(({ text }) => text === tag.text)}
              onClick={() => addOrRemoveTag(tag)}
            >
              <span
                style={{ background: tag.color }}
                className={styles.productRowTagBody}
              >
                {tag.text}
              </span>
            </li>
          ))}
        </ul>
      </div>
      <div className={styles.tagListButtons}>
        <Button
          onClick={() => setLocalTags([])}
          data-white
          className={styles.tagListReset}
        >
          Сбросить
        </Button>
        <Button
          data-black
          disabled={!confirmAvailable}
          handlePromise={handleConfirm}
          show-loading
        >
          Применить
        </Button>
      </div>
    </div>
  );
};

export default TaggingTagSelect;
