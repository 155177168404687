import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  useContext,
} from "react";
import mainStyles from "../../Card&News.module.css";
import styles from "./Stories.module.css";
import descImage from "assets/images/adding-news.svg";
import Button from "UI/Button";
import Icon from "UI/Icon";
import {
  useNavigate,
  useNavigationType,
  useSearchParams,
} from "react-router-dom";
import { toast } from "react-toastify";
import NewsTable from "./components/StoriesTable";
import { MainContext } from "pages/Main/Main";

enum StoriesTabsType {
  CURRENT = "current",
  ARCHIVE = "archive",
}

const Stories: React.FC = () => {
  const nav = useNavigate();
  const navigationType = useNavigationType();

  let [searchParams, setSearchParams] = useSearchParams();
  const {
    StoriesService: {
      archive,
      error,
      loadStories,
      setError,
      stories,
      setArchive,
      setStories,
    },
  } = useContext(MainContext);

  const type = searchParams.get("type");

  const isCurrent = type === StoriesTabsType.CURRENT;
  const isArchive = type === StoriesTabsType.ARCHIVE;
  useEffect(() => {
    if (error) {
      loadStories();
      setError(null);
    }
    if (navigationType === "POP") return;
    setSearchParams({ type: "current" }, { replace: true });
  }, []);
  return (
    <div className={styles.page}>
      {/* Описание */}
      <div className={mainStyles.description}>
        <img src={descImage} alt="Описание" />
        <div className={mainStyles.descriptionTextBlock}>
          <h1 className={mainStyles.descriptionTitle}>Добавление новостей</h1>
          <p className={mainStyles.descriptionText}>
            Создавайте и публикуйте интересные новости, которые будут видны
            вашим покупателям на главной странице приложения. Они будут
            находиться под картой лояльности в разделе «Новости». Это поможет
            вам легко и быстро рассказать о чем-то важном и привлечь внимание
            ваших покупателей. Например, вы можете рассказать через новости о
            новых блюдах в меню, новой акции или открытии нового места.
          </p>
        </div>
      </div>

      {/* Кнопки действий */}
      <div className={styles.actionsContainer}>
        <div className={styles.newsType}>
          <Button
            className={isCurrent ? styles.activeType : ""}
            onClick={() => setSearchParams({ type: StoriesTabsType.CURRENT })}
          >
            Текущие
          </Button>
          <Button
            className={isArchive ? styles.activeType : ""}
            onClick={() => setSearchParams({ type: StoriesTabsType.ARCHIVE })}
          >
            Архив
          </Button>
        </div>
        <Button data-black onClick={() => nav("../create-story")}>
          <Icon icon="add" /> Создать
        </Button>
      </div>

      <div
        className={`${styles.newsContainer} ${
          isCurrent && !stories?.length && styles.emptyCurrent
        } ${isArchive && !archive?.length && styles.emptyArchive}`}
      >
        {isCurrent && stories?.length ? (
          <NewsTable
            key={0}
            stories={stories}
            setStories={setStories}
            updateList={loadStories}
          />
        ) : isArchive && archive?.length ? (
          <NewsTable
            key={1}
            stories={archive}
            setStories={setArchive}
            updateList={loadStories}
            isArchive
          />
        ) : (
          <p>
            {isCurrent
              ? "Нет текущих новостей"
              : "Нет заархивированных новостей"}
          </p>
        )}
      </div>
    </div>
  );
};

export default Stories;
