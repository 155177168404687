import { useContext, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { AnimatePresence, motion } from "framer-motion";
import getProductTags from "api/tagging/get-product-tags";
import appContext from "utils/app-context";
import taggingSortOptions from "utils/tagging-sort-options";
import { Product, SortOptions } from "types/tagging";
import DropDown from "pages/Main/pages/Analytics/components/DropDown/DropDown";
import styles from "./Tagging.module.css";
import IllustrationCard from "assets/images/tagging-card.svg";
import TaggingSortOptions from "./components/TaggingSortOptions";
import Icon from "UI/Icon";
import Button from "UI/Button";
import TaggingProductRow from "./components/TaggingProductRow";
import TaggingNewProduct from "./components/TaggingNewProduct";

const PAGE_SIZE = 50;

const Tagging = () => {
  const [products, setProducts] = useState<Product[]>([]);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [query, setQuery] = useState("");
  const [total, setTotal] = useState(0);
  const [sortBy, setSortBy] = useState(SortOptions.UPDATED_AT);
  const timer = useRef<NodeJS.Timeout | null>(null);
  const abortControllerRef = useRef<AbortController | null>(null);
  const [newProduct, setNewProduct] = useState<Product | null>(null);

  const fetchProducts = async (page: number, reset = false) => {
    if (isFetching) return;
    setIsFetching(true);
    setLoading(true);

    abortControllerRef.current?.abort();
    abortControllerRef.current = new AbortController();

    const res = await getProductTags(
      {
        sortBy,
        page,
        productName: query,
        size: PAGE_SIZE,
      },
      abortControllerRef.current.signal
    );
    if (res === "canceled") return;
    if (!res) {
      toast.warning("Не удалось получить список товаров");
    } else if ("description" in res) {
      toast.warning(`Ошибка: ${res.description}`);
    } else {
      setProducts((prev) => (reset ? res.items : [...prev, ...res.items]));
      setTotal(res.total);
      setHasMore(res.items.length > 0);
    }

    setIsFetching(false);
    setLoading(false);
  };

  useEffect(() => {
    setPage(0);
    fetchProducts(0, true);
  }, [sortBy]);

  useEffect(() => {
    if (timer.current) clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      setPage(0);
      fetchProducts(0, true);
    }, 300);
  }, [query]);

  useEffect(() => {
    const handleScroll = () => {
      if (loading || isFetching || !hasMore) return;

      if (
        window.innerHeight + window.scrollY >=
        document.body.scrollHeight - 200
      ) {
        setPage((prev) => prev + 1);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [loading, isFetching, hasMore]);

  useEffect(() => {
    if (page > 0) {
      fetchProducts(page);
    }
  }, [page]);

  return (
    <div className={styles.sectionContent}>
      <div className={styles.pageDescContainer}>
        <img src={IllustrationCard} alt="" />
        <div className={styles.pageDescTextContent}>
          <h2>Теги для увеличения продаж</h2>
          <p>
            Тэги на карточках товара привлекают внимание, облегчают выбор,
            увеличивают продажи, <br /> создают имидж бренда, отражают текущие
            тренды и повышают информативность для <br /> покупателей.
          </p>
        </div>
      </div>

      <div className={styles.sectionTitle}>
        <hr className={styles.line} />
        <div className={styles.sectionTitleRow}>
          <h2>Тегирование</h2>
        </div>
      </div>

      <div className={styles.taggingContainer}>
        <div className={styles.taggingTop}>
          <label
            className={styles.taggingSearchContainer}
            htmlFor="label-search"
          >
            <input
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              type="text"
              placeholder="Поиск товара с тегами"
              id="label-search"
            />
          </label>
          <div className={styles.taggingTopOptions}>
            <div className={styles.taggingSortOptionsContainer}>
            <DropDown
              hasValue
              filterComponent={
                <TaggingSortOptions current={sortBy} set={setSortBy} />
              }
            >
              <Icon icon="sort" />
              <span>
                {taggingSortOptions.find((o) => o.value === sortBy)?.title}
              </span>
            </DropDown>
            </div>
            <div className={styles.taggingTopActions}>
              <span>{total} {getDeclension(total)}</span>
              <Button
                icon="add"
                data-black
                onClick={() =>
                  setNewProduct({
                    id: Date.now(),
                    productName: "",
                    tags: [],
                    updatedAt: Date.now(),
                  })
                }
                style={{ flexDirection: "row-reverse" }}
              >
                Добавить товар
              </Button>
            </div>
          </div>
        </div>

        <div className={styles.taggingTableContainer}>
          <table>
            <thead>
              <tr>
                <td>Товар с тегами</td>
                <td style={{ width: 500 }}>Теги</td>
                <td style={{ width: 140 }}>Дата обновления</td>
                <td style={{ width: 140 }}>Действия</td>
              </tr>
            </thead>
            <motion.tbody variants={variants} initial="closed" animate="open">
              <AnimatePresence mode="popLayout">
                <TaggingNewProduct
                  newProduct={newProduct}
                  setNewProduct={setNewProduct}
                  setTotal={setTotal}
                  update={() => fetchProducts(0, true)}
                />
                {products.map((p) => (
                  <TaggingProductRow
                    key={p.id}
                    p={p}
                    setProducts={setProducts}
                    setTotal={setTotal}
                  />
                ))}
              </AnimatePresence>
            </motion.tbody>
          </table>
          {(loading && products.length)? (
           <div className="loader"/>
          ) : (
            <div className={styles.taggingTablePlaceHolder}>
              {query.length ? (
                <>
                  <h3>Ничего не найдено</h3>
                  <p>По запросу "{query}" ничего нет</p>
                </>
              ) : (
                <>
                  <h3>Нет товаров с тегами</h3>
                  <p>Добавьте товары и выберите теги для них. <br /> Они будут отображаться здесь.  </p>
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const getDeclension = (num: number) => {
  const cases = ["запись", "записи", "записей"];
  if (num % 10 === 1 && num % 100 !== 11) return cases[0];
  if ([2, 3, 4].includes(num % 10) && ![12, 13, 14].includes(num % 100)) return cases[1];
  return cases[2];
};

const variants = {
  open: { transition: { staggerChildren: 0.03, delayChildren: 0.05 } },
  closed: { transition: { staggerChildren: 0.05, staggerDirection: -1 } },
};

export default Tagging;
