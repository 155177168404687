import ErrorResponce from "types/error-response";
import { Product, Tag } from "types/tagging";
  export default async (id:Product['id'],newName:string): Promise<Product | null | ErrorResponce> => {
    const token = localStorage.getItem('token')
    if(!token) return null
    try {
      const API_URL = new URL(process.env.REACT_APP_API_URL as string);
      API_URL.pathname += `product-tags/${id}/productName`;
      const responce = await fetch(API_URL, {
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": token,
        },
        method: "PATCH",
        body:JSON.stringify({newName})
      });
      return await responce.json();
    } catch (error) {
      console.log("🚀 ~ error:", error);
      return null;
    }
  };
  