
import React, { useContext } from 'react'
import Icon from './Icon'
import RouteItem, { RoutesCategory } from 'types/route-item'
import { Link, useLocation } from 'react-router-dom'
import appContext from 'utils/app-context'
import { MainContext } from 'pages/Main/Main'

type Props = {
    routesList:RoutesCategory[]
}

const NavigationBar = ({routesList}: Props) => {
  const location = useLocation()
  const rootPath = location.pathname.split("/")[2];
  const {hiddenRoutes} = useContext(appContext)
  const {LicensesService:{licenses}} = useContext(MainContext)
  const showPickupPage = licenses && (licenses.delivery || licenses.pickup)
  return (
    <aside className='navigation'>
        <Link to={'/main'}><Icon icon='logoSwipOffice'/></Link>
        <nav>
              {routesList.map(({routes,title},i) => (
            <ul key={i}>
              {title && <li className='nav-title'>{title}</li>}
              {routes.map(({title,route,navBarIcon}) => {
                if(route.path === 'pickup&delivery' && !showPickupPage) return null
                const isCurrent = rootPath === route.path
                if(!title.length || !navBarIcon || hiddenRoutes.includes(route.path || '')) return null
                return (
                  <li className={isCurrent ? 'current' : ''} key={title}>
                      <Link to={route.path || ''}>
                      {navBarIcon}
                      {title}</Link>
                  </li>
                )
              })}
            </ul>
              ))}
        </nav>
    </aside>
  )
}

export default NavigationBar