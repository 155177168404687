import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import Button from "UI/Button";
import filterStyles from "../../Transactions.module.css";
import styles from "./TransactionsDatePicker.module.css";
import TransactionsCalendar from "./components/TransactionsCalendar";
import datePresets, {
  findMatchingPreset,
  PresetKeys,
} from "./date-presets";
import TransactionsCalendarPresets from "./components/TransactionsCalendarPresets";
import dayjs from "dayjs";
import DateRange from "types/date-range";
import { FilterContext } from "../../../../components/DropDown/DropDown";
import TransactionsTimePicker from "./components/TransactionsTimePicker";
import { TimeRange } from "../../Transactions";

type Props = {
  selectedRange:DateRange,
  selectRange:(range:DateRange) => void
  time:TimeRange
  setTime:(t:TimeRange) => void 
};

const defaultPreset = datePresets.month;

const TransactionsDatePicker = ({selectedRange,selectRange,setTime,time}: Props) => {
  const [range, setRange] = useState<DateRange>(selectedRange);
  const [selectedPreset, setSelectedPreset] = useState<PresetKeys>("month");
  const {close} = useContext(FilterContext)
  // Update the date range and the selected preset
  const handlePresetSelect = (presetTitle: PresetKeys, range: DateRange) => {
    setRange(range);
    setSelectedPreset(presetTitle);
  };
  useEffect(() => {
    const currentPreset = findMatchingPreset(range.start, range.end);
    if (selectedPreset !== currentPreset) setSelectedPreset(currentPreset);
    if(range.start.isAfter(range.end)) {
      setRange({end:range.start.startOf('day'),start:range.start.endOf('day')})
    }
  }, [range]);
  return (
    <div className={styles.datePicker}>
      <div className={styles.pickersContainer}>
        <TransactionsCalendarPresets
          selectedPreset={selectedPreset}
          onPresetSelect={handlePresetSelect}
        />
        <div className={styles.calendarsWrapper}>
        <div className={styles.calendarsContainer}>
          <TransactionsCalendar
            setRange={setRange}
            selectedPreset={selectedPreset}
            displayDate={range.start}
            range={range}
            setInputDate={(d) => setRange({...range,start:d.startOf('day')})}
            setStartDate
          />
          <TransactionsCalendar
            setRange={setRange}
            selectedPreset={selectedPreset}
            displayDate={range.end}
            range={range}
            setInputDate={(d) => setRange({...range,end:d.endOf('day')})}
            setEndDate
          />
        </div>
         <TransactionsTimePicker time={time} setTime={setTime}/>
        </div>
      </div>
      <div className={styles.actionContainer}>
        <Button
          className={filterStyles.buttonReset}
          onClick={() => setRange(defaultPreset!.getRange())}
        >
          Сбросить
        </Button>
        <Button
          className={filterStyles.buttonConfirm}
          onClick={() => {
            selectRange(range)
            close()
          }}
        >
          Применить
        </Button>
      </div>
    </div>
  );
};

export default TransactionsDatePicker;
