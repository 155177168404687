import React, { MouseEventHandler, useContext, useEffect, useLayoutEffect, useRef, useState } from "react";
import PageWrapper from "UI/PageWrapper";
import styles from "./Auth.module.css";
import Icon from "UI/Icon";
import { AnimatePresence, motion } from "framer-motion";
import auth from "api/auth/auth";
import appContext from "utils/app-context";
import Button from "UI/Button";
import dayjs from "dayjs";

const Auth = () => {
  const [authData, setauthData] = useState({ email: "", pass: "" });
  const [stage, setstage] = useState<"email" | "pass">("email");
  const [showPass, setshowPass] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const {handleLogin} = useContext(appContext)
  const isdisabled = () => {
    if (stage === "email") return !authData.email;
    if (stage === "pass") return !authData.pass;
  };

  const handleClick:MouseEventHandler<HTMLButtonElement> = async (e) => {
    e.preventDefault()
   if (stage === 'pass') {
      const result = await handleLogin(authData.email,authData.pass)
      if (result && '@type' in result) {
        setError(result.description)
        setstage('email')
        setauthData({ email: "", pass: "" })
      }
    } else setstage("pass");
  };
  useEffect(() => {
    document.body.click()
  }, [])

  const clearError = () => {
    if(error) setError(null)
  }

  return (
    <PageWrapper className={styles.page} id="auth">
      <div className={styles.authContainer}>
        <div className={styles.authWrapper}>
          <Icon icon="logoSwipOffice" />
          <form className={styles.authForm} autoComplete="off">
          <AnimatePresence>
          {error && <motion.div
          layout
            className={styles.error}
            initial={{
              opacity:0,
              height:0,
              marginTop:-20
            }}
            animate={{
              opacity:1,
              height:56,
              marginTop:0
            }}
            exit={{
              opacity:0,
              height:0,
              marginTop:-20
            }}
            >
            <p>{error}</p>
          </motion.div>}
          </AnimatePresence>
            <div className={styles.formFieldContainer}>
              <motion.div
                animate={{ y: stage === "email" ? 0 : "-100%" }}
                className={styles.formField}
              >
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={authData.email}
                  placeholder="&nbsp;"
                  onFocus={clearError}
                  onChange={(e) =>
                    setauthData({ ...authData, email: e.target.value })
                  }
                  autoFocus
                />
                <label htmlFor="email">Email</label>
              </motion.div>
              <motion.div
                initial={false}
                animate={{ y: stage === "pass" ? 0 : "100%" }}
                className={styles.formField}
              >
                <input
                  type={showPass ? 'text' :"password"}
                  id="pass"
                  name="password"
                  value={authData.pass}
                  placeholder="&nbsp;"
                  onFocus={clearError}
                  onChange={(e) =>
                    setauthData({ ...authData, pass: e.target.value })
                  }
                />
                <label htmlFor="pass">Password</label>
                <span className={`${styles.showPassBtn} ${showPass ? styles.showPassBtnShowed : ''}`} onClick={() => setshowPass(!showPass)}/>
              </motion.div>
            </div>
            <Button
              onClick={handleClick}
              disabled={isdisabled()}
              className={styles.formButton}
            >
              {stage === "email" ? "Далее" : "Войти"}
            </Button>
          </form>
          <div className={styles.formCheckBoxWrapper}>
            <input type="checkbox" id="remeber" />
            <label htmlFor="remeber" />
            <span>Запомнить меня</span>
          </div>
        </div>
      </div>
      <div className={styles.footer}>
        <p>© SWiP {dayjs().format("YYYY")}</p>
      </div>
    </PageWrapper>
  );
};

export default Auth;
