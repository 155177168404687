import ErrorResponce from "types/error-response";
import PickupSettings, {
  PickupSettingsWithStoreId,
} from "types/pickup-settings";

export default async (
  settings?: PickupSettings
): Promise<PickupSettingsWithStoreId | null | ErrorResponce > => {
    
  const token = localStorage.getItem("token");
  if (!token) return null;
  try {
    const API_URL = new URL(process.env.REACT_APP_API_URL as string);
    API_URL.pathname += `pickup-settings`;
    const responce = await fetch(API_URL, {
      headers: {
        "Content-Type": "application/json",
        "X-Auth-Token": token,
      },
      body: settings ? JSON.stringify(settings) : undefined,
      method: settings ? "PUT" : 'GET',
    });
    return await responce.json();
  } catch (error) {
    console.log("🚀 ~ error:", error);
    return null;
  }
};
