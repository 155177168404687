import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import styles from "./TransactionsTable.module.css";
import formatNum from "utils/format-num";
import Button from "UI/Button";
import Icon from "UI/Icon";
import OrdersResponse, { Order } from "types/orders-responce";
import getOrders from "api/analytics/get-orders";
import OrdersFilters from "types/orders-filters";
import { toast } from "react-toastify";
import TransactionFilter from "../../../../components/DropDown/DropDown";
import TransactionRow from "./components/TransactionRow";
import { motion } from "framer-motion";
import TransactionRowPlaceHolder from "./components/TransactionRowPlaceHolder";
import TransactionsCounter from "./components/TransactionsCounter";
import { notification } from "hooks/use-notifications";
import { TimeRange } from "../../Transactions";

type Props = {
  filters: OrdersFilters;
  setFilters: Dispatch<SetStateAction<OrdersFilters>>;
  time:TimeRange
};

const TransactionsTable = ({ filters, setFilters ,time}: Props) => {
  const [orders, setOrders] = useState<Order[]>([]);
  const [loading, setLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(false); // Флаг для предотвращения множественных запросов
  const [hasMore, setHasMore] = useState(true); // Флаг, есть ли ещё данные
  const [query, setQuery] = useState("");
  const [page, setPage] = useState(0);
  const timer = useRef<NodeJS.Timeout | null>(null);
  const refTableHead = useRef<HTMLTableRowElement>(null);
  const abortControllerRef = useRef<AbortController | null>(null);

  const updateOrders = async (newPage: number) => {
    setIsFetching(true);
    setLoading(true);

    // Отменяем предыдущий запрос, если он существует
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    // Создаем новый AbortController
    const abortController = new AbortController();
    abortControllerRef.current = abortController;

    const res = await getOrders(filters, newPage,abortController.signal,time);
    if(res === 'canceled') return
    if (!res) {
      notification("Не удалось получить список транзакций",'warning');
    } else if ("description" in res) {
      notification(`Ошибка при получении транзакций:\n${res.description}`,'warning');
    } else {
      setOrders((prevOrders) => [...prevOrders, ...res.items]); // Добавляем новые записи
      setHasMore(res.items.length > 0); // Если порция пустая, больше данных нет
    }
    setLoading(false);
    setIsFetching(false); // Сбрасываем флаг после завершения загрузки
  };

  useEffect(() => {
    setOrders([]); // Сбрасываем список при изменении фильтров
    setPage(0); // Сбрасываем страницу
    setHasMore(true); // Сбрасываем флаг наличия данных
    updateOrders(0); // Загружаем данные для новой страницы
    if(filters.customerNumber !== query) setQuery(filters.customerNumber || '')
  }, [filters]);

  useEffect(() => {
    const handleScroll = () => {
      if (loading || isFetching || !hasMore) return;

      // Проверяем, достигнут ли конец страницы
      if (
        window.innerHeight + window.scrollY >=
        document.body.scrollHeight - 200
      ) {
        setIsFetching(true); // Устанавливаем флаг, чтобы избежать лишних запросов
        setPage((prevPage) => prevPage + 1); // Увеличиваем страницу
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [loading, isFetching, hasMore]);

  useEffect(() => {
    if (page > 0) {
      updateOrders(page); // Загружаем данные при увеличении страницы
    }
  }, [page]);

  useEffect(() => {
    const handleScroll = (e: Event) => {
      if (!refTableHead.current) return;
      const { top } = refTableHead.current.getBoundingClientRect();
      const c = styles.isSticky;
      const contains = refTableHead.current.classList.contains(c);
      if (!top && !contains) {
        refTableHead.current.classList.add(c);
      } else if (top && contains) {
        refTableHead.current.classList.remove(c);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    if (timer.current) clearTimeout(timer.current);

    timer.current = setTimeout(() => {
      setFilters((prev) => ({ ...prev, customerNumber: query }));
    }, 200);
  }, [query]);

  return (
    <div className={styles.body} data-loading={loading && !orders.length}>
      <div className={styles.searchRow}>
        <div className={styles.inputContainer}>
          <input
            className={styles.input}
            type="text"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            placeholder="Поиск по номеру телефона или ID"
          />
          {loading && (
            <motion.div
              className={styles.loadingIcon}
              animate={{ rotate: [0, 360] }}
              transition={{ repeat: Infinity, ease: "linear", duration: 0.5 }}
            >
              <Icon icon="loading" />
            </motion.div>
          )}
        </div>
        <div className={styles.searchRowActions}>
          <p className={styles.countText}>
            <TransactionsCounter filters={filters}/>
          </p>
          <Button className={styles.downloadBtn}>
            <Icon icon="dowlnoad" />
          </Button>
        </div>
      </div>
      <div className={styles.tableWrapper}>
        <table className={styles.table}>
          <thead>
            <tr ref={refTableHead}>
              <td>
                <span>Дата</span>
              </td>
              <td>
                <span>Теги</span>
              </td>
              <td>
                <span>Покупатель</span>
              </td>
              <td>
                <span>Оплата и тип заказа</span>
              </td>
              <td>
                <span>Баллы</span>
              </td>
              <td>
                <span>Скидка</span>
              </td>
              <td>
                <span>Сумма</span>
              </td>
              <td></td>
            </tr>
          </thead>
          <tbody>
            {orders.map((o) => (
              <TransactionRow order={o} key={o.id} />
            ))}
          </tbody>
        </table>
        {isFetching &&
          hasMore &&
        <table className={styles.table}>
          <tbody>
            {
               Array.from({ length: 5 }).map((_, i) => (
                <TransactionRowPlaceHolder key={i} />
              ))
            }
          </tbody>
        </table>
        }
      </div>
      {/* {!hasMore && <p className={styles.endMessage}>Больше записей нет</p>} */}
    </div>
  );
};

export default TransactionsTable;
