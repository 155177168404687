import getLicenses from "api/get-licenses"
import { useEffect, useState } from "react"
import LicensesStatus from "types/licenses"
import { notification } from "./use-notifications"

export default () => {
    const [licenses, setlicenses] = useState<LicensesStatus | null>(null)

    const fetchLicenses = async () => {
        const res = await getLicenses()
        if(!res) notification('Не удалось получить статус лицензий доставки и самовывоза','warning')
        else if ('description' in res) notification(`Не удалось получить статус лицензий доставки и самовывоза: ${res.description}`,'warning')
        else {
            setlicenses(res)
        }
    }
    useEffect(() => {
        fetchLicenses()
    }, [])

    return {licenses,fetchLicenses}
}