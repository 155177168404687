import React, { useContext } from 'react'
import { SortOptions } from 'types/tagging'
import Button from 'UI/Button'
import taggingSortOptions from 'utils/tagging-sort-options'
import styles from '../Tagging.module.css'
import { FilterContext } from 'pages/Main/pages/Analytics/components/DropDown/DropDown'
type Props = {
  current:SortOptions
  set:(v:SortOptions) => void 
}

const TaggingSortOptions = ({current,set}: Props) => {
  const {close} = useContext(FilterContext)
  return (
    <div className={styles.sortOptionsListSelect}>
      <ul>
        {taggingSortOptions.map(({title,value}) => (
          <li data-selected={value === current} key={value}><Button onClick={() => {
            set(value)
            close()
          }}>{title}</Button></li>
        ))}
      </ul>
    </div>
  )
}

export default TaggingSortOptions