import { AnimatePresence, motion } from 'framer-motion'
import React, { isValidElement, useContext } from 'react'
import { Link, useLocation, useRoutes } from 'react-router-dom'
import RouteItem from 'types/route-item'
import PageWrapper from 'UI/PageWrapper'
import styles from './Main.module.css'
import { MainContext } from './Main'

type Props = {
    title:string
    routes:RouteItem[]
}

const MainPage = ({routes,title}: Props) => {
    const location = useLocation();
    const element = useRoutes(routes.map(({ route }) => route));
    const {LicensesService:{licenses}} = useContext(MainContext)
    const rootPath = location.pathname.split("/")[3];
    const currentRoute = routes.find(({route:{path}}) => path?.includes(rootPath))
    if(!element) return null
    return (
      <section className={styles.section}>
        <motion.div
        style={{overflow:'hidden'}}
        animate={{
          height:currentRoute?.hideTopBar ? 0 : 'auto',
          opacity:currentRoute?.hideTopBar ? 0 : 1,
        }}
        transition={{duration:0.1}}
        >
        <h1 style={{fontSize:32}}>{title}</h1>
        <ul className={styles.navigation}>
          {routes.map(({ route, title ,hideTopBar}) => {
            
            const isCurrent = rootPath === route.path
            if (!title.length || hideTopBar) return null;
            return (
              <li key={title} className={isCurrent ? styles.current : ""}>
                <Link to={route.path as string}>
                  {title} <hr />
                </Link>
              </li>
            );
          })}
        </ul>
        </motion.div>
        <div className={styles.page}>
          <AnimatePresence mode="wait" initial={false}>
            <PageWrapper key={rootPath} className="section-wrapper">
              {React.cloneElement(element)}
            </PageWrapper>
          </AnimatePresence>
        </div>
      </section>
    );
}

export default MainPage