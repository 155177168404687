import ErrorResponce from "types/error-response";
import PickupSettings, {
  PickupSettingsWithStoreId,
} from "types/pickup-settings";

type Responce = {
    settingsCommonMode:boolean
}

export default async (
    newSettingsCommonMode?: boolean
): Promise<Responce | null | ErrorResponce> => {
    
  const token = localStorage.getItem("token");
  if (!token) return null;

    const isGet = typeof newSettingsCommonMode === 'undefined'

  try {
    const API_URL = new URL(process.env.REACT_APP_API_URL as string);
    API_URL.pathname += `pickup-settings/mode`;
    const responce = await fetch(API_URL, {
      headers: {
        "Content-Type": "application/json",
        "X-Auth-Token": token,
      },
      body: !isGet ? JSON.stringify({newSettingsCommonMode}) : undefined,
      method: !isGet ? "PATCH" : 'GET',
    });
    if(responce.status === 204 && newSettingsCommonMode) return {settingsCommonMode:newSettingsCommonMode}
    const result = await responce.json();
    return result

  } catch (error) {
    console.log("🚀 ~ error:", error);
    return null;
  }
};
