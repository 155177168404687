import { SortOptions } from "types/tagging";

export default [
    {
        title:'По дате добавления тега',
        value:SortOptions.UPDATED_AT
    },
    {
        title:'По алфавиту',
        value:SortOptions.PRODUCT_NAME
    },
    {
        title:'По тегам',
        value:SortOptions.TAGS_COUNT
    },
]